<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-card
      elevation="4"
      class="mt-0"
    >
      <v-data-table
        :headers="headers"
        :items="listCoupons"
        :loading="isLoading"
        :server-items-length="couponsMeta.totalItems"
        :options.sync="options"
        :items-per-page="30"
        :footer-props="{ 'items-per-page-options': [10, 30, 50] }"
      >
        <template v-slot:item.amount="{ item }">
          {{ item.type === types.flat ? item.amount : item.percentage + " %" }}
        </template>
        <template v-slot:item.left="{ item }">
          {{ item.max_usage - item.total_usage ?? 0 }}
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip
            :color="getColor(item.status)"
            dark
          >
            {{ item.status }}
          </v-chip>
        </template>
        <template v-slot:item.start_at="{ item }">
          {{ formatDate(item.start_at) }}
        </template>
        <template v-slot:item.end_at="{ item }">
          {{ formatDate(item.end_at) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <template v-v-if="$admin.can('coupon-update')">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  :color="item.status === 'active' ? 'red' : 'success'"
                  small
                  class="mr-2"
                  @click="toggleItemStatus(item)"
                >
                  {{
                    item.status === "active"
                      ? " mdi-cancel"
                      : "mdi-check-outline"
                  }}
                </v-icon>
              </template>
              <span>{{
                $t(
                  "coupons." +
                    (item.status === "active" ? "deactivate" : "activate")
                )
              }}</span>
            </v-tooltip>
            <v-icon
              small
              class="mr-2"
              @click="showItem(item)"
              v-if="$admin.can('coupon-view')"
            >
              mdi-eye
            </v-icon>
            <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
              v-if="$admin.can('coupon-update')"
            >
              mdi-pencil
            </v-icon>
            <template v-v-if="$admin.can('coupon-create')">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    small
                    class="mr-2"
                    @click="cloneItem(item)"
                  >
                    mdi-content-duplicate
                  </v-icon>
                </template>
                <span>{{ $t("coupons.clone") }}</span>
              </v-tooltip>
            </template>
            <v-icon
              small
              @click="deleteItem(item)"
              v-if="$admin.can('coupon-delete')"
            >
              mdi-delete
            </v-icon>
          </template>
        </template>
        <template v-slot:no-data>
          {{ $t("no_data_available") }}
        </template>
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>{{ $t("coupons.title") }}</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="addItem()"
            >
              {{ $t("coupons.create") }}
            </v-btn>
            <delete-coupon-dialog
              v-if="$admin.can('coupon-delete')"
            ></delete-coupon-dialog>
          </v-toolbar>
          <v-row class="mx-2 mx-0">
            <v-col
              cols="12"
              sm="4"
            >
              <v-text-field
                outlined
                dense
                v-model="options.search"
                :label="$t('search')"
                class=""
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="3"
            >
              <v-autocomplete
                outlined
                dense
                clearable
                v-model="options.hub_id"
                :items="hubs"
                item-text="name"
                item-value="id"
                :label="$t('hub')"
                :search-input.sync="search_hub"
                :loading="is_loading_hubs"
                no-filter
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              sm="2"
            >
              <v-menu
                ref="start_at"
                v-model="start_at_menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="startAtFormatted"
                    outlined
                    dense
                    clearable
                    :label="$t('coupons.start_at')"
                    persistent-hint
                    append-icon="mdi-calendar"
                    v-bind="attrs"
                    style="flex: 1 1 0"
                    @blur="date = parseDate(startAtFormatted)"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="options.start_at"
                  no-title
                  @input="start_at_menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col
              cols="12"
              sm="2"
            >
              <v-menu
                ref="end_at"
                v-model="end_at_menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="endAtFormatted"
                    outlined
                    dense
                    clearable
                    :label="$t('coupons.end_at')"
                    append-icon="mdi-calendar"
                    v-bind="attrs"
                    @blur="date = parseDate(endAtFormatted)"
                    v-on="on"
                    style="flex: 1 1 0"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="options.end_at"
                  no-title
                  @input="end_at_menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col
              cols="12"
              sm="1"
            >
              <v-autocomplete
                outlined
                dense
                clearable
                v-model="options.status"
                :items="['active', 'inactive']"
                :label="$t('status')"
                :disabled="isLoading"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import debounce from "lodash/debounce";
import moment from "moment";

export default {
  components: {
    DeleteCouponDialog: () => import("./dialogs/Delete.vue"),
  },

  watch: {
    options: {
      handler: debounce(async function () {
        await this.$store.dispatch("coupons/list", this.options).then(() => {
          if (this.options.page > this.providersMeta?.lastPage) {
            this.options.page = 1;
          }
        });
      }, 1000),
      deep: true,
    },
    search_hub: debounce(function (search) {
      if (this.is_loading_hubs) return;
      this.is_loading_hubs = true;

      this.$store
        .dispatch("hubs/list", {
          itemsPerPage: 30,
          for: "inbound",
          search,
        })
        .then(() => {
          this.is_loading_hubs = false;
        });
    }, 1000),
  },

  computed: {
    ...mapGetters({
      listCoupons: "coupons/list",
      couponsMeta: "coupons/meta",
      hubs: "hubs/activeHubs",
    }),
    startAtFormatted: {
      get() {
        return this.formatDate(this.options.start_at);
      },
      set() {
        this.options.start_at = null;
      },
    },
    endAtFormatted: {
      get() {
        return this.formatDate(this.options.end_at);
      },
      set() {
        this.options.end_at = null;
      },
    },
    isLoading: {
      get() {
        return this.$store.state.coupons.isLoading;
      },
      set(value) {
        this.$store.commit("coupons/SET_LOADING", value);
      },
    },
  },

  data() {
    return {
      options: {},
      types: {
        flat: "flat",
        percentage: "percentage",
      },
      start_at: moment().format("YYYY-MM-DD"),
      end_at: moment().format("YYYY-MM-DD"),
      start_at_menu: false,
      end_at_menu: false,
      search_hub: "",
      is_loading_hubs: false,
      headers: [
        {
          text: this.$t("coupons.code"),
          align: "start",
          value: "code",
          width: "20%",
        },
        {
          text: this.$t("coupons.total_usage"),
          align: "start",
          value: "total_usage",
        },
        {
          text: this.$t("coupons.left"),
          align: "start",
          value: "left",
        },
        {
          text: this.$t("coupons.amount_percentage"),
          align: "start",
          value: "amount",
        },
        {
          text: this.$t("coupons.start_at"),
          align: "start",
          value: "start_at",
        },
        {
          text: this.$t("coupons.end_at"),
          align: "start",
          value: "end_at",
        },
        {
          text: this.$t("status"),
          value: "status",
          width: "15%",
        },
        {
          text: this.$t("actions"),
          value: "actions",
          align: "end",
          sortable: false,
          width: "15%",
        },
      ],
    };
  },
  methods: {
    parseDate(date) {
      if (!date) return null;

      return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
    },
    formatDate(date) {
      if (!date) return "-";
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
    showItem(item) {
      this.$router.push({
        name: "coupons.view",
        params: { id: item.id },
      });
    },
    toggleItemStatus(item) {
      this.$store.dispatch("coupons/toggleStatus", item);
    },
    editItem(item) {
      this.$router.push({
        name: "coupons.edit",
        params: { id: item.id },
      });
    },

    addItem() {
      this.$store.dispatch("coupons/initialize");
      this.$router.push({ name: "coupons.create" });
    },

    deleteItem(item) {
      this.$store.dispatch("coupons/openDeleteForm", item);
    },

    getColor(status) {
      if (status === "active") return "green";
      else return "red";
    },
    async cloneItem(item) {
      try {
        this.isLoading = true;
        await this.$store.dispatch("coupons/clone", item);
        await this.$store.dispatch(
          "alerts/success",
          this.$t("created_successfully")
        );
      } catch (error) {
        await this.$store.dispatch(
          "alerts/error",
          error.response?.data?.message
        );
      } finally {
        this.isLoading = false;
      }
    },
  },
  async created() {
    this.$store.commit("SET_PAGE_SUBTITLE", "");
    this.$store.commit("SET_SUBTITLE_ID", "");
  },
};
</script>
